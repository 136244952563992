<template>
  <div class="row">
    <div class="col-12 g">
        <div class="card">
            <div class="card-header">
                <h5>
                    المواعيد <button class="btn btn-success" v-b-modal.create style="width: 30px; border-radius: 50%; padding: 0px; height: 30px">
                     <i class="fa fa-plus"></i>
                    </button>
                </h5>
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-bordered table-sm">
                        <thead>
                            <th>المدرسة</th>
                            <th>اليوم</th>
                            <th>الساعة</th>
                            <th>
                                السبب
                            </th>
                            <th>
                                سجل النشاط
                            </th>
                            <th>خيارات</th>
                        </thead>
                        <tbody>
                            <tr v-for="u in calendars" :key="u._id">
                                <td>
                                    {{ u.schoolname }}
                                </td>
                                <td>
                                    {{ u.date }}
                                </td>
                                <td>
                                    {{ u.time }}
                                </td>
                                <td>
                                    {{ u.reason }}
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-primary" v-b-modal.logs @click="logs = u.logs; current = u">
                                        <i class="fa fa-list"></i>
                                        عرض ({{ u.logs.length }})
                                    </button>
                                </td>
                                <td>
                                    <b-dropdown size="sm" text="خيارات">
                                        <b-dropdown-item @click="current = u" v-b-modal.edit>
                                            <i class="fa fa-edit"></i>
                                            تعديل
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="create" title="اضافة موعد" hide-footer>
        <div class="form-group">
          <label for="">اسم المدرسة</label>
          <input type="text"
            class="form-control" v-model="create.schoolname">
        </div>
        <div class="form-group">
          <label for="">السبب</label>
          <textarea class="form-control" v-model="create.reason" rows="3"></textarea>
        </div>
        <div class="form-group">
          <label for="">تاريخ الزيارة</label>
          <input type="date"
            class="form-control" v-model="create.date">
        </div>
        <div class="form-group">
          <label for="">موعد الزيارة</label>
          <input type="time"
            class="form-control" v-model="create.time">
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-success" @click="createNow()">
                <i class="fa fa-check"></i>
                اضافة الموعد
            </button>
        </div>
    </b-modal>
    <b-modal id="edit" title="تعديل الموعد" hide-footer>
        <div class="form-group">
          <label for="">اسم المدرسة</label>
          <input type="text"
            class="form-control" v-model="current.schoolname">
        </div>
        <div class="form-group">
          <label for="">السبب</label>
          <textarea class="form-control" v-model="current.reason" rows="3"></textarea>
        </div>
        <div class="form-group">
          <label for="">تاريخ الزيارة</label>
          <input type="date"
            class="form-control" v-model="current.date">
        </div>
        <div class="form-group">
          <label for="">موعد الزيارة</label>
          <input type="time"
            class="form-control" v-model="current.time">
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-success" @click="edit()">
                <i class="fa fa-check"></i>
                تعديل الموعد
            </button>
        </div>
    </b-modal>
    <b-modal id="logs" title="سجل النشاط" hide-footer>
        <div class="col-12 table-responsive">
        <div class="g">
            <div class="input-group mbx-3">
            <input type="text" class="form-control" v-model="logg" placeholder="اضافة نشاط..." aria-describedby="basic-addon2">
            <div class="input-group-append">
                <button class="input-group-text btn-primary" @click="addlog()" id="basic-addon2">اضافة</button>
            </div>
            </div>
        </div>
            <table class="table table-sm table-hover table-bordered">
                <thead>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        التفاصيل
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(l, i) in logs" :key="i">
                        <td>
                            {{ l.date }}
                        </td>
                        <td>
                            {{ l.content }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormInput, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
    components: {
        // Layouts
        BDropdown, BDropdownItem,
        BModal,BFormInput},
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            calendars: [],
            create: {money: 0, date: new Date().toISOString().split("T")[0], interested: 0, time: new Date().toISOString().split("T")[1].split(".")[0].split(":")[0] + ":" + new Date().toISOString().split("T")[1].split(".")[0].split(":")[1]},
            logs: [],
            details: "",
            current: {},
            logg: ""
        }
    },
    created(){
        this.getR()
    },
    methods: {
        edit(){
            var g = this;
            $.post(api + '/reseller/dates/calendar/edit', {
                jwt: this.user.jwt,
                id: g.current._id,
                create: g.current
            }).then(function(r){
                g.getR()
                $("#edit___BV_modal_header_ > button").click()
            })
        },
        addlog(){
            var g = this;
            if(g.logg == "" || !g.logg){
                return "";
            }
            $.post(api + '/reseller/dates/calendar/log', {
                jwt: this.user.jwt,
                id: this.current._id,
                log: this.logg
            }).then(function(r){
                g.getR()
                $("#logs___BV_modal_header_ > button").click()
            })
        },
        getR(){
            var g = this;
            $.post(api + '/reseller/dates/calendar/list', {
                jwt: this.user.jwt
            }).then(function(r){
                g.calendars = r.response;
            })
        },
        createNow(){
            var g = this;
            $.post(api + '/reseller/dates/calendar/create', {
                 jwt: this.user.jwt,
                 create: this.create
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم انشاء الموعد بنجاح", 100)
                    g.getR()
                    $("#create___BV_modal_header_ > button").click()
                }
            }).fail(function(){
                alert("حدث مشكلة في الاتصال", 200)
            })
        }
    }
}
</script>

<style>

</style>